/* 
Texts transparència, als path s'ha de posar el path per arribar fins la seva pàg
Al title s'hi posa el text que es vol mostrar
I al cName és el class name s'hi ha de posar 'dropdown-link'
*/

export const MenuItems = [
    {
      title: 'Col·laboradors',
      path: '/collaboradors',
      cName: 'dropdown-link'
    },
    {
      title: 'Patrocinadors',
      path: '/patrocinadors',
      cName: 'dropdown-link'
    }
  ];